import React from 'react';
import {Link} from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

import styles from './blog.module.css';

export default function Blog({pageContext}) {
  const {posts} = pageContext;

  return (
    <Layout>
      <SEO title="Blog" />

      <div className={styles.Container}>
        <h1 className={styles.Heading}>Blog</h1>

        <section className={styles.PostsContainer}>
          {posts.map(post => (
            <Link
              key={post.id}
              to={`/blog/${post.slug}`}
              className={styles.PostLink}
            >
              <div className={styles.PostImage}>
                <Img fluid={post.image.fluid} />
              </div>
              <p className={styles.PostTitle}>
                <strong>{post.title}</strong>
              </p>
            </Link>
          ))}
        </section>
      </div>
    </Layout>
  );
}
